import { render, staticRenderFns } from "./DescricaoAGt.vue?vue&type=template&id=094d79da&scoped=true&lang=pug&"
import script from "./DescricaoAGt.vue?vue&type=script&lang=js&"
export * from "./DescricaoAGt.vue?vue&type=script&lang=js&"
import style0 from "./DescricaoAGt.styl?vue&type=style&index=0&id=094d79da&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "094d79da",
  null
  
)

export default component.exports
<template lang="pug">
    article#business
        img(:src="business[galeriaAtivo].bg.img", :alt="business[galeriaAtivo].titulo", :style="business[galeriaAtivo].bg.style").bgAGt
        div.wrapper.flex
            DescricaoAGT(:logo="descricaoLogo", :conteudo="descricaoConteudo")
            GaleriaAGT(:galeria="business", :galeriaAtivo.sync="galeriaAtivo")
</template>

<script>
import DescricaoAGT from '@/components/DescricaoAGt/DescricaoAGt.vue'
import GaleriaAGT from '@/components/GaleriaAGt/GaleriaAGt.vue'

export default {
    components: {
        DescricaoAGT,
        GaleriaAGT
    },
    data() {
        return {
            galeriaAtivo: 0,
            descricaoLogo: {
                img: require('@/assets/images/logo/logo_gtcompany_business.png'), 
                alt: 'Gt Company Business'
            },
            descricaoConteudo: {
                titulo: 'Negócios próprios.', 
                texto: 'A GT Business traz a essência e visão de seu fundador, Dr. Geninho Thomé. Por isso, cada negócio tem o propósito de mudar a vida das pessoas de um jeito inteligente e único.'
            },
            business: [
                {
                    titulo: "NEOORTHO",
                    subtitulo: "TECNOLOGIA & SAÚDE",
                    texto: "A Neoortho é uma fabricante de implantes ortopédicos, com um portfólio composto por mais de 11 mil itens, em crescente expansão e com excelente aceitação no mercado.",
                    img: require('@/assets/images/business/neoortho-mobile.jpg'),
                    bg: {
                        img: require('@/assets/images/business/neoortho-mobile.jpg'),
                        style: {}
                    }
                },
                {
                    titulo: "GT BUILDING",
                    subtitulo: "EMPREENDIMENTOS IMOBILIÁRIOS",
                    texto: "Incorporadora com empreendimentos inovadores que, pelo uso de tecnologia, inovação e qualidade construtiva, cria projetos de vida diferenciados para diferentes públicos.",
                    img: require('@/assets/images/business/gtbuilding-mobile.jpg'),
                    bg: {
                        img: require('@/assets/images/business/gtbuilding-mobile.jpg'),
                        style: {}
                    }
                },
                {
                    titulo: "FACULDADE ILAPEO",
                    subtitulo: "EDUCAÇÃO",
                    texto: "Referência internacional em odontologia, a ILAPEO começou como braço de pesquisa e ensino odontológico da Neodent, empresa líder no setor de implantes dentários.",
                    img: require('@/assets/images/business/ilapeo-mobile.jpg'),
                    bg: {
                        img: require('@/assets/images/business/ilapeo-mobile.jpg'),
                        style: {}
                    }
                },
                // {
                //     titulo: "ECOMAIS",
                //     subtitulo: "ENERGIA",
                //     texto: "Os briquetes Ecomais são a evolução do carvão. São mais econômicos devido a seu rendimento e eficiência, além de mais ecológicos, pois sua queima tem chamas limpas e com menos fumaça.",
                //     img: require('@/assets/images/business/ecomais-mobile.jpg'),
                //     bg: {
                //         img: require('@/assets/images/business/ecomais-mobile.jpg'),
                //         style: {}
                //     }
                // },
            ]
        }
    },
}
</script>

<style lang="stylus" scoped src="./Business.styl"></style>
<template lang="pug">
    section#galeriaAGt
        button(@click="toggleGaleria(galeriaAtivo - 1)").btnPartners.prev
            include ../../assets/images/svgs/arrow_white.svg
        button(@click="toggleGaleria(galeriaAtivo + 1)").btnPartners.next
            include ../../assets/images/svgs/arrow_white.svg
        p.counter 
            span.atual {{ (galeriaAtivo + 1) }}
            span.total /{{ galeria.length }}
        div.itemGaleria(v-for="item, index in galeria", v-if="index == galeriaAtivo", :key="`${item.titulo}`" v-touch:swipe="toggleGaleriaSwipe")
            div.descricao
                h4 {{ item.titulo }}
                h5 {{ item.subtitulo }}
                p(v-html="item.texto")

            figure
                img(:src="item.img", :alt="`${item.titulo} - ${item.subtitulo}`")
</template>

<script>
export default {
    props: {
        galeriaAtivo: Number,
        galeria: Array
    },
    methods: {
        toggleGaleriaSwipe(direction) {
            if (direction == "left")
                this.toggleGaleria(this.$props.galeriaAtivo + 1)
            else if(direction == "right")
                this.toggleGaleria(this.$props.galeriaAtivo - 1)
        },
        toggleGaleria(index = 0) {
            if (index > this.$props.galeria.length - 1)
                index = 0;
            else if (index < 0)
                index = this.$props.galeria.length - 1

            this.$emit('update:galeriaAtivo', index);
        }
    },
}
</script>

<style lang="stylus" scoped src="./GaleriaAGt.styl"></style>